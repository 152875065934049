<template>
    <div class="mysound-popup">
        <div class="web">
            <div class="mysound-header2" @click="moCloseTooltip">
                <div class="mysound-header2-title">
                    <img src="/media/images/icon_back_40.svg" alt="" @click="back">
                    <p>퍼스널</p>
                </div>
                <div class="mysound-close web" @click="$emit('closePopup')">
                    <img src="/media/images/icon_close_40.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mobile">
            <div class="mo-mysound-header">
                <div class="mo-mysound-back" @click="back">
                    <img src="/media/images/icon_back_24.svg" alt="">
                </div>
                <div>
                    <p>퍼스널</p>
                </div>
                <!-- <div class="mo-mysound-close" @click="$emit('closePopup')">
          <img src="/media/images/icon_close_24.svg" alt="">
        </div> -->
            </div>
        </div>

        <div class="mysound-detail-content">
            <div class="content-name mb-24" @click="moCloseTooltip">
                <p v-html="musicInfo.brandMusicNm" />
            </div>

            <div class="content-item mb-24" @click="moCloseTooltip">
                <p class="content-item-title mb-8 txt-white">미리 듣기</p>
                <div>
                    <div class="flex-align" style="align-items: center;">
                        <img id="image" class="music-btn style-cursor" src="/media/img/brand_Play.svg" style="width: 24px; height: 24px;"  @click="startMusic(musicInfo.brandMusicId, $event)"/>
                        <!-- /media/img/brand_Pause.svg -->
                        <input id="seekslider" class="range-style" type="range" min="0" max="100" step="1" value="0" disabled>
                    </div>
                    <video id="video" controls hidden />
                </div>
            </div>

            <div class="content-item mb-24 web">
                <p class="content-item-title mb-8 txt-white">지정 시간</p>
                <div>
                    <div class="select-box-area">
                        <div class="select-box" @click="openWebTime([playTime, '지정'])" :class="playType === 'S' || playType === 'A' ? '' : 'on'">{{ playTime }}</div>
                    </div>
                </div>
            </div>

            <div class="content-item mb-24 mobile" @click="moCloseTooltip">
                <p class="content-item-title mb-8 txt-white">지정 시간</p>
                <div>
                    <div class="select-box-area">
                        <div class="select-box" @click="openMoTime([playTime, '지정'])" :class="playType === 'S' ? '' || playType === 'A' : 'on'">{{ playTime }}</div>
                    </div>
                </div>
            </div>

            <div class="content-item mb-24 web tooltip-area">
                <p class="content-item-title mb-8">
                    <span class="txt-white mr-8">반복 주기</span>
                    <img class="style-cursor" @mouseover="openTooltip" @mouseleave="closeTooltip" src="media/img/mysound/question-line.svg" fill="rgba(255,255,255,1)" width="18" height="18">
                </p>
                <div>
                    <div class="select-box-area">
                        <div class="select-box mb-8" @click="openWebTime([startTime, '반복'])" :class="playType === 'R' || playType === 'A' ? '' : 'on'">{{ startTime }} 부터</div>
                        <div class="select-box" @click="openWebRepeatTime" :class="playType === 'R' || playType === 'A' ? '' : 'on'">{{ repeatTime }}시간마다</div>
                    </div>
                </div>
                <div class="tooltip-box" v-if="tooltipStatus">
                    <p class="txt-white">하루(00:00 ~ 23:59)를 기준으로</p>
                    <p class="txt-white">설정한 시간에 반복 재생됩니다.</p>
                </div>
            </div>

            <div class="content-item mb-24 mobile tooltip-area">
                <p class="content-item-title mb-8">
                    <span class="txt-white mr-8" @click="moCloseTooltip">반복 주기</span>
                    <img @click="moOpenTooltip" src="media/img/mysound/question-line.svg" fill="rgba(255,255,255,1)">
                </p>
                <div @click="moCloseTooltip">
                    <div class="select-box-area personal">
                        <div class="select-box" @click="openMoTime([startTime, '반복'])" :class="playType === 'R' || playType === 'A' ? '' : 'on'">{{ startTime }} 부터</div>
                        <div class="select-box" @click="openMoRepeatTime" :class="playType === 'R' || playType === 'A' ? '' : 'on'">{{ repeatTime }}시간마다</div>
                    </div>
                </div>
                <div class="tooltip-box" v-if="tooltipStatus">
                    <p class="txt-white">하루(00:00 ~ 23:59)를 기준으로</p>
                    <p class="txt-white">설정한 시간에 반복 재생됩니다.</p>
                </div>
            </div>

            <div class="mo-delete-btn mobile" @click="deletePersonal">
                <p>삭제</p>
            </div>
        </div>

        <div class="mysound-footer-btn" @click="moCloseTooltip">
            <div class="delete-btn web style-cursor" @click="deletePersonal">
                <p class="txt-white">삭제</p>
            </div>

            <div class="common-btn-style" :style="{ 'pointer-events': playType !== 'A' ? '' : 'none', 'opacity': playType !== 'A' ? '1' : '0.3' }" @click="updateMusicInfo">
                <p>수정</p>
            </div>
        </div>

        <StarttPc v-if="webTimeStatus" :userInfo="userInfo" @insertPc="setTime" @closePc="closeTimeBox"></StarttPc>
        <StartMobile v-if="moTimeStatus" :userInfo="userInfo" @insertMobile="setTime" @closeMobile="closeTimeBox"></StartMobile>

        <RepeatPc v-if="webintervalStauts" :repeatTime="repeatTime" :repeatList="repeatList" @insertPc="setRepeatTime" @closePc="closeTimeBox"></RepeatPc>
        <RepeatMobile v-if="mointervalStauts" :repeatTime="repeatTime" :repeatList="repeatList" @insertMobile="setRepeatTime" @closeMobile="closeTimeBox"></RepeatMobile>

        <DeleteConfirm v-if="deleteStatus" :musicInfo="musicInfo" @closeDeletePopup="refresh" @closeConfirm="deleteStatus=false"></DeleteConfirm>
        <UpdateConfirm v-if="updateStatus" @closeUpdateConfirm="refresh"></UpdateConfirm>
    </div>
</template>

<script>
import StarttPc from '@/components/modal/Player/mysound/timeBox/StartPc.vue';
import StartMobile from '@/components/modal/Player/mysound/timeBox/StartMobile.vue';
import RepeatPc from '@/components/modal/Player/mysound/timeBox/RepeatPc.vue';
import RepeatMobile from '@/components/modal/Player/mysound/timeBox/RepeatMobile.vue';
import DeleteConfirm from '@/components/modal/Player/mysound/confirm/DeleteConfirm';
import UpdateConfirm from '@/components/modal/Player/mysound/confirm/UpdateConfirm';

import VueCookies from 'vue-cookies';
import axios from 'axios';
import Hls from "hls.js";
import $ from 'jquery';

export default {
  name: 'personalDetail',

  components: {
    StarttPc,
    StartMobile,
    RepeatPc,
    RepeatMobile,
    DeleteConfirm,
    UpdateConfirm
  },

  props: [
    'musicInfo'
  ],

  data () {
    return {
      // 기본값
      playTime: '09:00',
      startTime: '09:00',
      playType: 'A',
      repeatTime: '1',

      // 시간 선택 팝업
      userInfo: {},
      webTimeStatus: false,
      moTimeStatus: false,
      option: null,
      webintervalStauts: false,
      mointervalStauts: false,
      repeatList: [],

      // 컨펌창
      deleteStatus: false,
      updateStatus: false,

      // 툴팁
      tooltipStatus: false
    };
  },

  created () {
    this.brandMusicNm = this.musicInfo.brandMusicNm;
    this.playTime = this.musicInfo.mySoundDetail.selectTime;
    this.startTime = this.musicInfo.mySoundDetail.startTime;
    this.repeatTime = this.musicInfo.mySoundDetail.repeatTime;
    this.playType = this.musicInfo.mySoundDetail.playType == null ? 'A' : this.musicInfo.mySoundDetail.playType;

    this.checkTime();
  },

  methods: {
    openWebTime (item) { // 웹 시간 선택 팝업 열기
      this.userInfo.startTime = item[0];
      this.option = item[1];
      this.playType = item[1] === '지정' ? this.playType = 'S' : this.playType = 'R';
      this.webTimeStatus = true;
    },

    openMoTime (item) { // 모바일 시간 선택 팝업 열기
      this.userInfo.startTime = item[0];
      this.option = item[1];
      this.playType = item[1] === '지정' ? this.playType = 'S' : this.playType = 'R';
      this.moTimeStatus = true;
    },

    setTime (item) { // 시간 세팅
      if (this.option === '지정') {
        this.playTime = item.hour + ':' + item.minute;
      } else {
        this.startTime = item.hour + ':' + item.minute;
        this.repeatTime = '1';
        this.checkTime();
      }
    },

    checkTime () { // 반복 주기 시작시간에 따른 반복 주기 제한
      const num = 23 - parseInt(this.startTime.substring(0, 2));
      const numList = [1, 2, 3, 6, 12];

      // 초기화
      this.repeatList = [];

      for (const i in numList) {
        if (numList[i] < num + 1) {
          this.repeatList.push(numList[i]);
        } else {
          this.repeatList.push(0);
        }
      }
    },

    openWebRepeatTime () { // 웹 반복 주기 간격 설정
      this.playType = 'R';
      this.webintervalStauts = true;
    },

    openMoRepeatTime () { // 모바일 반복 주기 간격 설정
      this.playType = 'R';
      this.mointervalStauts = true;
    },

    setRepeatTime (item) { // 반복 주기 시간 간격 값 세팅
      this.repeatTime = item;
    },

    closeTimeBox () { // 팝업 닫기
      this.webTimeStatus = false;
      this.moTimeStatus = false;
      this.webintervalStauts = false;
      this.mointervalStauts = false;
    },

    openTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    closeTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moOpenTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moCloseTooltip () {
      this.tooltipStatus = false;
    },

    updateMusicInfo () {
      const userId = VueCookies.get('userId');
      const brandMusicNm = this.brandMusicNm;
      const brandMusicId = this.musicInfo.brandMusicId;
      const mySoundDetail = {
        playAt: this.musicInfo.mySoundDetail.playAt,
        selectTime: this.playTime,
        startTime: this.startTime,
        repeatTime: this.repeatTime,
        playType: this.playType,
        brandSpaceMusicId: this.musicInfo.mySoundDetail.brandSpaceMusicId
      };

      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/mysound/updateMySound', { userId, brandMusicNm, brandMusicId, mySoundDetail }, { headers })
        .then(() => {
          this.updateStatus = true;
        })
        .catch(e => {
          console.log(e);
        });
    },

    deletePersonal () { // 삭제
      this.deleteStatus = true;
    },

    startMusic (url, e) {
      const video = document.getElementById('video');
      const videoId = video.id;
      const imgId = 'image';
      const seek = 'seekslider';
      url = "/stream/getMusic?" + url;

      if (this.playUrl == null || this.playUrl !== url) {
        this.playerListener(url, seek, videoId, imgId);
      }

      $(e.currentTarget).attr("src", (index, attr) => {
        if (attr.match("_Play")) {
          video.play();
          return attr.replace("_Play.svg", "_Pause.svg");
        } else {
          video.pause();
          return attr.replace("_Pause.svg", "_Play.svg");
        }
      });
    },

    playerListener (url, seek, videoId, imgId) {
      this.playUrl = url;
      // const _store = this.$store;
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      let hls = null;
      const seekslider = document.getElementById(seek);
      let video = document.getElementById(videoId);

      seekslider.addEventListener("click", vidSeek, false);
      video.addEventListener("timeupdate", seektimeupdate, false);
      video.addEventListener("ended", () => {
        $("#" + imgId).attr("src", (index, attr) => {
          video.pause();
          return attr.replace("_Play.svg", "_Pause.svg");
        });
      });

      function vidSeek () {
        var seekto = video.duration * (seekslider.value / 100);
        video.currentTime = seekto;
      }

      function seektimeupdate () {
        var newtime = video.currentTime * (100 / video.duration);
        seekslider.value = newtime;
        // _store.commit("setPlayNav", newtime);
      }

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic (url) {
        const tg = window.location.host.indexOf('localhost') !== -1 ? "http://13.209.152.125:10084" + url : url;
        // const tg = "http://13.209.152.125:10084" + url;
        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          // addListener(); // add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById(videoId);
          const pa = video.parentElement;

          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement(videoId);
          v.id = 'video';
          v.playsInline = true;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
          // addListener(); // add video player listener
          v.querySelector('source').src = tg;
          v.play(); // autoplay for next song
        } else {
          // todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    },

    refresh () {
      this.deleteStatus = false;
      this.updateStatus = false;
      this.$emit('refresh');
    },

    back () {
      this.$emit('closeAppendPopup');
    }
  }
};
</script>

<style scoped>
  .content-name p {
    font-style: normal;
    letter-spacing: -1px;
    color: #fff;
    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: Lato;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  .content-item p {
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  .select-box {
    background: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 16px;
  }

  .mobile {
    display: none;
  }

  .select-box.on {
    opacity: 0.3;
  }

  .content-item-title {
    display: flex;
    align-items: center;
  }

  .tooltip-area {
    position: relative;
  }

  .tooltip-box {
    position: absolute;
    width: 225px;
    padding: 8px 12px;
    background: #FD4E6D;
    border-radius: 5px;
    top: 24px;
    left: 0;
  }

  .web .tooltip-box {
    top: 15%;
  }

  .mysound-footer-btn {
    display: flex;
    justify-content: space-between;
    padding:0 5%;
  }
  .delete-btn {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .delete-btn p {
    font-size: 16px;
    color:rgba(255, 255, 255, 0.80);
  }

  @media all and (max-width: 1024px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;
    }

    .mysound-popup .mysound-header2 {
      margin-bottom: 0;
    }

    .mysound-popup .mo_space_header {
      background: #282828;
    }

    .mysound-detail-content {
      position: relative;
    }

    .mo-delete-btn {
      position: absolute;
      bottom: 0;
      color: hsla(0,0%,100%,.6);
    }
  }
 @media all and (max-width: 500px) {
  .common-btn-style p{font-size:18px;}
  .personal > div {margin-bottom: 8px;}
  .mysound-footer-btn {
    position: absolute;
    bottom: 16px;
    width: 100%;
    padding:0 5%;
  }

  .content-item-title img {
    padding-top:1px;
  }
 }
</style>
