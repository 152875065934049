<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                하루(00:00~23:59)를 기준으로 반복 재생되므로<br/>
                설정 불가한 시간입니다.
            </h3>
            <div class="single-file-check">
                <p @click="$emit('closeImpossConfirm', '1')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'impossibleConfirm'
};
</script>

<style scoped>

</style>
