<template>
    <div class="mysound-popup">
        <div class="web">
            <div class="mysound-header2">
                <div class="mysound-header2-title">
                    <img src="/media/images/icon_back_40.svg" alt="" @click="back">
                    <p>파일 업로드</p>
                </div>
                <div class="mysound-close" @click="$emit('closePopup')">
                    <img src="/media/images/icon_close_40.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mobile">
            <div class="mo-mysound-header">
                <div class="mo-mysound-back" @click="back">
                    <img src="/media/images/icon_back_24.svg" alt="">
                </div>
                <div>
                    <p>파일 업로드</p>
                </div>
                <div class="mo-mysound-close" @click="$emit('closePopup')">
                    <img src="/media/images/icon_close_24.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mysound-detail-content" v-if="finalPage">
            <div class="content-title mb-16">
                <p>먼저, 등록할 음원 파일을 업로드해주세요.</p>
            </div>

            <div class="content-box" @click="openFileUpload" @mouseover="uploadFile" v-if="fileName === ''">
                <img src="/media/img/mysound/icon_upload_mp3.svg" class="mb-8">
                <p>클릭하거나 드래그로 mp3 파일을 업로드해주세요.</p>
                <p>파일 용량은 1분 이하로 제한됩니다.</p>
            </div>

            <div class="file-name-box" v-else>
                <p>{{ fileName }}</p>
                <div class="mysound-close" @click="deleteFile">
                    <img src="/media/images/icon_close_40.svg">
                </div>
            </div>

            <div class="alert-message">
                <p v-if="alertStatus">{{ alertMsg }}</p>
            </div>

            <input type="file" id="file" accept=".mp3" hidden @change="checkFile">
        </div>

        <div class="mysound-detail-content" v-else>
            <div class="content-title mb-16">
                <p>업로드한 오디오 파일명을 설정해주시겠어요?</p>
            </div>

            <div>
                <input type="text" v-model="soundTitle" maxlength="10" style="width: 100%;" placeholder="최대 10자까지 입력 가능합니다.">
            </div>

            <div class="alert-message">
                <p v-if="alertStatus">파일명을 입력해주세요.</p>
            </div>
        </div>

        <div class="mysound-footer">
            <div class="common-btn-style" @click="nextPage" :style="{ 'pointer-events': fileName !== '' ? '' : 'none', 'opacity': fileName !== '' ? '1' : '0.3' }" v-if="finalPage">
                <p>다음</p>
            </div>

            <div class="common-btn-style" v-else :style="{ 'pointer-events': soundTitle !== '' ? '' : 'none', 'opacity': soundTitle !== '' ? '1' : '0.3' }" @click="confirmInsertMusic">
                <p>완료</p>
            </div>
        </div>

        <Loading v-if="loading"></Loading>
        <UploadConfirm v-if="uploadStatus" @closeUploadConfirm="refresh"></UploadConfirm>
    </div>
</template>

<script>
import UploadConfirm from '@/components/modal/Player/mysound/confirm/UploadConfirm.vue';
import Loading from '@/components/modal/Player/mysound/etc/Loading.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'mysoundFile',

  components: {
    UploadConfirm,
    Loading
  },

  data () {
    return {
      // 업로드 파일
      fileName: '',
      musicPath: null,
      playTime: null,
      soundTitle: '',

      // 페이지
      finalPage: true,

      // 얼럿
      alertMsg: '',
      alertStatus: false,
      alertStatus2: false,

      // 로딩
      loading: false,

      // 컨펌 팝업
      uploadStatus: false
    };
  },

  methods: {
    openFileUpload () { // 파일 업로드 창 열기
      document.getElementById('file').click();
    },

    uploadFile () { // 파일 드래그 앤 드랍 기능
      const dropbox = document.querySelector('.content-box');

      // 박스 안에 drag 하고 있을 때
      dropbox.addEventListener('dragover', (e) => {
        e.preventDefault();
        // this.style.backgroundColor = 'rgb(13 110 253 / 25%)';
      });

      // 박스 밖으로 drag가 나갈 때
      dropbox.addEventListener('dragleave', (e) => {
        // this.style.backgroundColor = 'white';
      });

      // 박스 안에 drop 했을 때
      dropbox.addEventListener('drop', (e) => {
        e.preventDefault();

        // 파일 이름을 text로 표시
        document.getElementById('file').files = e.dataTransfer.files;

        this.checkFile(); // e.dataTransfer.files[0].name
      });
    },

    checkFile () { // 파일 확장자 및 용량 체크
      const file = document.getElementById('file').files[0];
      const num = file.name.lastIndexOf('.');
      const name = file.name.substring(0, num);

      this.alertStatus = false;

      // eslint-disable-next-line
      const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
      const regExp2 = /\s/g;

      if (regExp.test(name) || regExp2.test(name)) {
        this.alertMsg = '파일명에 특수문자 또는 공백이 없게해주세요.';
        this.alertStatus = true;
        return false;
      }

      if (file.name.substring(num).toLowerCase() === '.mp3') {
        this.loading = true;

        const fileToUpload = file;
        const userId = VueCookies.get('userId');
        const aToken = VueCookies.get("aToken");
        const headers = { "X-AUTH-TOKEN": aToken };

        const formData = new FormData();
        formData.append('fileToUpload', fileToUpload);
        formData.append('userId', userId);

        axios
          .post('/api/mysound/uploadChosenFile', formData, { headers })
          .then(res => {
            // 정보 넣어주기
            this.musicPath = res.data.result.fileOnServer;
            this.playTime = res.data.result.playtime;

            // alert 지우기
            this.alertStatus = false;
            if (this.playTime > 60) { // 1분 이상의 음원 파일은 등록 불가능
              this.deleteFile();
              this.alertMsg = '업로드는 1분 이내의 파일만 가능합니다.';
              this.alertStatus = true;
            } else {
              this.fileName = file.name;
            }
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alertMsg = 'mp3 파일만 업로드 가능합니다.';
        this.alertStatus = true;
      }
    },

    deleteFile () { // 업로드 파일 삭제
      document.getElementById('file').value = '';
      this.fileName = '';
      this.loading = false;
    },

    nextPage () { // 파일명 입력 페이지로 이동
      this.finalPage = false;
    },

    confirmInsertMusic () { // 파일명 체크
      if (this.soundTitle === '') {
        this.alertStatus2 = true;
        return false;
      }
      this.alertStatus2 = false;
      this.insertMusic();
    },

    insertMusic () { // 파일 및 파일 정보 등록
      this.loading = true;
      const spaceId = VueCookies.get('spaceId');
      const soundTitle = this.soundTitle;
      const fileOnServer = this.musicPath;
      const playtime = this.playTime;
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/mysound/registMySoundByFile', { spaceId, soundTitle, fileOnServer, playtime, userId }, { headers })
        .then(() => {
          this.uploadStatus = true;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    refresh () {
      this.uploadStatus = false;
      this.$emit('refresh');
    },

    back () { // 파일 업로드 팝업 닫기
      if (this.finalPage) {
        this.$emit('closeAppendPopup');
      } else {
        this.finalPage = true;
        this.soundTitle = '';
      }
    }
  }
};
</script>

<style scoped>
  .mysound-popup .mo_space_header{
      display: none;
  }
  .mysound-popup .mo_space_header{
      display: none;
  }

  .mysound-detail-content {
    display: flex;
    flex-direction: column;
  }

  .content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #FFFFFF;
  }

  .content-box {
    width: 100%;
    height: 240px;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
  }

  .content-box:hover {
    background: rgba(255, 255, 255, 0.04);
  }

  .content-box img {
    width: 82px;
  }

  .content-box p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.5px;

    color: #FFFFFF;

    opacity: 0.87;
  }

  .file-name-box {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    padding: 11px 24px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .file-name-box p {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 350;
    font-size: 18px;
    line-height: 26px;

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }

  .alert-message p {
    color: #E85454;
    font-size: 14px;
    margin-top: 8px;
  }

  .mobile-mysound-header {
    background: #282828;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 5%;
    padding-top: 13px;
    margin-bottom: 22px;
  }

  /* @media all and (max-width: 500px) {
    .mysound-popup .mysound-header2{
      margin-bottom: 0;
    }
    .mysound-popup .mo_space_header{
      display: block;
      background: #282828;
    }
    .mysound-popup .mysound-header2 .mysound-header2-title{
      display: none;
    }
    .content-title{
      font-size: 16px;
    }
    .mysound-detail-content {
      margin-top:16px;
    }
  } */
</style>
<style src="@/assets/css/mysound.css"></style>
