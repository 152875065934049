<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>마이 사운드 내의 동시 재생 가능 곡 수는<br> 최대 5곡입니다.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeConfirm')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'maxConfirm'
};
</script>

<style scoped>

</style>
