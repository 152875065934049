<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                퍼스널 음원은 최대 3대까지 등록 가능합니다.
            </h3>
            <div class="single-file-check">
                <p @click="$emit('closeConfirm')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'unableToRegister'
};
</script>

<style scoped>

</style>
