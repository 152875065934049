import { render, staticRenderFns } from "./MySoundText.vue?vue&type=template&id=33c7f13c&scoped=true"
import script from "./MySoundText.vue?vue&type=script&lang=js"
export * from "./MySoundText.vue?vue&type=script&lang=js"
import style0 from "./MySoundText.vue?vue&type=style&index=0&id=33c7f13c&prod&scoped=true&lang=css"
import style1 from "@/assets/css/mysound.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33c7f13c",
  null
  
)

export default component.exports