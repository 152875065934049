<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                하루(00:00 ~ 23:59)를 기준으로<br/>
                설정한 시간에 반복 재생됩니다.
            </h3>
            <div class="single-file-check">
                <p @click="$emit('closePossConfirm')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'possibleConfirm'
};
</script>

<style scoped>

</style>
