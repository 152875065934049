<template>
    <div class="mysound-popup">
        <div class="web">
            <div class="mysound-header2" @click="moCloseTooltip">
                <div class="mysound-header2-title">
                    <img src="/media/images/icon_back_40.svg" alt="" @click="back">
                    <p>텍스트 입력</p>
                </div>
                <div class="mysound-close" @click="$emit('closePopup')">
                    <img src="/media/images/icon_close_40.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mobile">
            <div class="mo-mysound-header">
                <div class="mo-mysound-back" @click="back">
                    <img src="/media/images/icon_back_24.svg" alt="">
                </div>
                <div>
                    <p>텍스트 입력</p>
                </div>
                <div class="mo-mysound-close" @click="$emit('closePopup')">
                    <img src="/media/images/icon_close_24.svg" alt="">
                </div>
            </div>
        </div>

        <div class="mysound-detail-content" v-if="finalPage">
            <div class="content-title mb-16">
                <p>음성 파일로 변환할 텍스트를 입력해주세요.</p>
            </div>

            <div class="content-text-box mb-16" @click="moCloseTooltip">
                <textarea v-model="message" style="width: 100%; height: 200px; overflow: auto;" maxlength="300" placeholder="내용을 입력해주세요." @change="chekcURL"></textarea>
                <p>{{ message.length }} <span>/ 300</span></p>
            </div>

            <div class="content-option-box flex-align mb-32">
                <div class="flex-align" @click="moCloseTooltip">
                    <p class="mr-16 txt-white">선호 목소리</p>

                    <div class="flex-align mr-16" @click="changeVoice('male')">
                        <!-- <img :src="gender === 'male' ? '/media/img/agree_check_on.png' : '/media/img/agree_check_of.png'">
            <p class="txt-white">남자</p> -->
                        <!-- <img :src="gender === 'male' ? '/media/img/agree_check_on.png' : '/media/img/agree_check_of.png'"> -->
                        <label for="check0">
                            <input type="radio" name="gender" checked/>
                            <span class="txt-white">🧑남자</span>
                        </label>
                    </div>

                    <div class="flex-align" @click="changeVoice('female')">
                        <!-- <img :src="gender === 'female' ? '/media/img/agree_check_on.png' : '/media/img/agree_check_of.png'">
            <p class="txt-white">여자</p> -->
                        <label for="check0">
                            <input type="radio" name="gender" />
                            <span class="txt-white">👩‍🦰 여자</span>
                        </label>
                    </div>
                </div>

                <div class="count-box flex-align">
                    <img class="web style-cursor mr-4" @mouseover="openTooltip" @mouseleave="closeTooltip" src="/media/img/mysound/icon_tooltip_20.svg" style="width: 20px;">
                    <img class="mobile mr-4" @click="moOpenTooltip" src="/media/img/mysound/icon_tooltip_20.svg" style="width: 18px;">
                    <p class="txt-white" @click="moCloseTooltip">이번달 잔여 미리듣기: {{ listenableCnt }}회</p>
                </div>

                <div class="mysound-tooltip2" v-if="tooltipStatus">
                    <p>미리듣기는 월 10회 가능합니다.<br>(텍스트 내용 및 선호 목소리가 동일할 경우 미리듣기의 횟수 제한은 없습니다.)</p>
                </div>
            </div>

            <div v-if="alertStatus">
                <p class="alert-message">{{ alertMsg }}</p>
            </div>

            <div style="display: none;">
                <audio id="play" :src="blobPath" controls></audio>
            </div>

            <div class="sound-box flex-align" @click="moCloseTooltip">
                <!-- <img src="/media/img/mysound/icon_listen_82.svg"> -->
                <div class="sound-box-item style-cursor" v-if="!replay" @click="createFile">
                    <img src="/media/img/mysound/btn_record_play.svg" alt="">
                    <p>미리듣기</p>
                </div>

                <div class="sound-box-item replay style-cursor" v-else @click="createFile">
                    <img src="/media/img/mysound/btn_record_stop.svg" alt="">
                    <p>정지하기</p>
                </div>
            </div>
        </div>

        <div class="mysound-detail-content" v-else @click="moCloseTooltip">
            <div class="content-title mb-16">
                <p>텍스트 파일 음원명을 설정해주시겠어요?</p>
            </div>

            <div>
                <input type="text" v-model="soundTitle" style="width: 100%;" maxlength="10" placeholder="최대 10자까지 입력 가능합니다.">
            </div>
        </div>

        <div class="mysound-footer" @click="moCloseTooltip">
            <div class="common-btn-style" v-if="finalPage" @click="nextPage" :style="{ 'pointer-events': blobPath != null ? '' : 'none', 'opacity': blobPath != null ? '1' : '0.3' }">
                <p>다음</p>
            </div>

            <div class="common-btn-style" @click="saveFile" v-else :style="{ 'pointer-events': soundTitle !== '' ? '' : 'none', 'opacity': soundTitle !== '' ? '1' : '0.3' }">
                <p>완료</p>
            </div>
        </div>

        <UploadConfirm v-if="uploadStatus" @closeUploadConfirm="refresh"></UploadConfirm>
        <Loading v-if="loading"></Loading>
    </div>
</template>

<script>
import UploadConfirm from '@/components/modal/Player/mysound/confirm/UploadConfirm.vue';
import Loading from '@/components/modal/Player/mysound/etc/Loading.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'mysoundText',

  components: {
    UploadConfirm,
    Loading
  },

  data () {
    return {
      // 카테고리
      categoryStatus: true,
      tooltipStatus: false,

      // 변환할 메세지
      message: '',

      // 남은 횟수
      listenableCnt: 10,

      // 목소리
      gender: 'male',
      soundTitle: '',

      finalPage: true,

      // alert
      alertStatus: false,
      alertMsg: '',

      // 확인팝업
      uploadStatus: false,

      // 로딩
      loading: false,

      fileContents: null,
      fileOnServer: null,
      playTime: null,
      blobPath: null,

      // 미리 듣기
      replay: false
    };
  },

  created () {
    this.getLimite();
  },

  methods: {
    getLimite () {
      const userId = VueCookies.get("userId");
      const spaceId = VueCookies.get('spaceId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      axios
        .post('/api/mysound/getTTSCount', { userId, spaceId }, { headers })
        .then(res => {
          // 초기화
          this.listenableCnt = 10;

          // 계산
          this.listenableCnt -= res.data.result;
        })
        .catch(e => {
          console.log(e);
        });
    },

    changeVoice (item) {
      if (this.gender !== item) {
        this.chekcURL();
        this.gender = item;
      }
    },

    async createFile () {
      if (this.message === '' && this.blobPath != null) {
        // 파일 미생성 - 텍스트 미입력 검사
        this.alertMsg = '음성 파일로 변환할 텍스트를 입력해주세요.';
        this.alertStatus = true;
        this.closeAlert();
      } else if (this.message !== '' && this.blobPath != null) {
        // 파일 생성 - 미리듣기
        let timer = null;

        if (!this.replay) {
          this.replay = true;
          document.getElementById('play').play();

          timer = setInterval(() => {
            const audio = document.getElementById('play');
            if (audio.ended) {
              clearInterval(timer);
              this.replay = false;
            }
          }, 100);
        } else {
          clearInterval(timer);
          const audio = document.getElementById('play');
          audio.pause();
          audio.currentTime = 0;
          this.replay = false;
        }
      } else {
        // 파일 미생성 - 파일 생성

        if (this.listenableCnt === 0) {
          this.alertMsg = '이용가능 횟수가 0회입니다.';
          this.alertStatus = true;
          this.closeAlert();
          return false;
        }

        if (this.message === '') {
          // 내용 미입력 시 얼럿
          this.alertMsg = '음성 파일로 변환할 텍스트를 입력해주세요.';
          this.alertStatus = true;
          this.closeAlert();
          return false;
        }

        if (this.gender == null) {
          // 선호 목소리 미선택 시 얼럿
          this.alertMsg = '선호 목소리를 선택해주세요';
          this.alertStatus = true;
          this.closeAlert();
          return false;
        }

        this.loading = true;
        const userId = VueCookies.get("userId");
        const spaceId = VueCookies.get("spaceId");
        const textOfMessage = this.message;
        const voiceType = this.gender;
        const voiceName = this.gender;

        const aToken = VueCookies.get("aToken");
        const headers = { "X-AUTH-TOKEN": aToken };

        const res = await axios.post('/api/mysound/listenTextPreviously', { userId, spaceId, textOfMessage, voiceType, voiceName }, { headers }).catch(e => { console.log('e', e.response); }).finally(() => { this.loading = false; });
        this.fileOnServer = res.data.result.fileOnServer;
        this.playTime = res.data.result.playtime;

        const file = await axios.post('/api/mysound/downloadTTSFile', res.data.result.fileOnServer, { responseType: 'blob' }, { headers }).catch(e => { console.log(e); }).finally(() => { this.loading = false; });
        const blob = new Blob([file.data], { type: file.headers['content-type'] });

        const url = window.URL || window.webkitURL;
        this.blobPath = url.createObjectURL(blob);

        await this.getLimite();
        this.loading = false;
        document.getElementById('play').play();
      }
    },

    closeAlert () {
      setTimeout(() => {
        this.alertStatus = false;
      }, 3000);
    },

    chekcURL () {
      if (this.blobPath != null) {
        this.blobPath = null;
      }
    },

    nextPage () {
      if (this.blobPath != null) {
        this.finalPage = false;
      }
    },

    openTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    closeTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moOpenTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moCloseTooltip () {
      this.tooltipStatus = false;
    },

    saveFile () {
      this.loading = true;
      const userId = VueCookies.get('userId');
      const spaceId = VueCookies.get('spaceId');
      const soundTitle = this.soundTitle;
      const fileOnServer = this.fileOnServer;
      const playtime = this.playtime;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/mysound/registMySoundByText', { userId, spaceId, soundTitle, fileOnServer, playtime }, { headers })
        .then(() => {
          this.loading = false;
          this.uploadStatus = true;
        })
        .catch(e => {
          console.log(e);
        });
    },

    refresh () {
      this.uploadStatus = false;
      this.$emit('refresh');
    },

    back () {
      if (this.finalPage) {
        this.$emit('closeAppendPopup');
      } else {
        this.finalPage = true;
        this.soundTitle = '';
      }
    }
  }
};
</script>

<style scoped>
  .mysound-popup .mo_space_header{
    display: none;
  }

  .mysound-content-detail {
    display: flex;
    flex-direction: column;
  }

  .content-title {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;

    text-align: center;
    letter-spacing: -0.01em;

    color: #FFFFFF;
  }

  .content-text-box {
    position: relative;
    width: 100%;
    height: 240px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    border:1px solid rgba(255, 255, 255, 0.16);
  }

  .content-text-box textarea{
    background:none;
  }

  .content-text-box p {
    position: absolute;
    bottom: 5%;
    left: 3%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
  }

  .content-text-box p span {
    opacity: 0.3;
  }

  .content-option-box {
    position: relative;
    margin-bottom:62px;
    justify-content: space-between;
  }

  .count-box p {
    color: rgba(225,225,225, 0.84)
  }

  .mysound-tooltip2 {
    position: absolute;
    top:110%;
    right:0;
    font-weight: 400;
    background: #FD4E6D;
    font-size:13px;
    color:#fff;
    padding:8px 12px;
    border-radius: 4px;
  }

  .mysound-tooltip2 p{
    font-weight: 400;
  }

  .sound-box {
    justify-content: center;
  }

  .sound-box-item {
    display: flex;
    padding: 10px 16px;
    border: 1px solid #fff;
    border-radius: 8px;
    cursor: pointer;
  }

  .sound-box-item:hover{
    background: rgba(225,225,225, 0.08);
    border: 1px solid #fff;
    border-radius: 8px;
    transition: ease-in-out 0.3s;
  }

  .sound-box-item.replay {
    background: rgba(255, 255, 255, 0.08);
  }

  .sound-box-item img {
    width: 18px;
    margin-right: 8px;
  }

  .sound-box-item p {
    color: #fff;
  }

  /* .sound-box img {
    width: 82px;
    height: 82px;
    border-radius: 50%;
  } */

  .alert-message {
    position: absolute;
    width: 90%;
    top: 82%;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
    background: #fff;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    color: #151515;
  }
  /* Voice 11/15 */
  .content-option-box > div > div label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-option-box > div > div span {
    vertical-align: middle;
    margin-left:4px;
  }

  [type="radio"] {
    vertical-align: middle;
    appearance: none;
    padding:0;
    border-radius: 50%;
    width: 1.25em;
    height: 1.25em;
    transition: border 0.5s ease-in-out;
    background: rgba(255, 255, 255, 0.08);
  }

  [type="radio"]:checked {
    border: 0.3em solid #fff;
    background: #FF2D55;
  }

  [type="radio"]:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) dotted tomato;
  }

  [type="radio"]:hover {
    background: rgba(255, 255, 255, 0.16);
    /* box-shadow: 0 0 0 max(4px, 0.2em) rgba(255, 255, 255, 0.08); */
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  [type="radio"]:disabled {
    background-color: lightgray;
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
  }

  [type="radio"]:disabled + p {
    opacity: 0.7;
    cursor: not-allowed;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .btn-area {
    width: auto;
  }

  @media all and (max-width: 1024px) {
    .mysound-tooltip {
      left: 62%;
      width: 38%;
    }

    .btn-area {
      width: 100%;
    }
  }

  @media all and (max-width: 500px) {
    .mysound-tooltip {
      left: 45%;
      width: 58%;
    }

    .mysound-tooltip p:first-child {
      font-size: 13px;
    }

    .content-title{
      font-size: 16px;
    }

    .mysound-detail-content {
      margin-top:16px;
    }

    .content-option-box{
      position: relative;
      display: block;
      margin-bottom:32px;
    }

    .content-option-box > div {
      font-size:16px;
      margin-bottom: 12px;
    }

    .content-option-box > div:nth-child(2){
      font-size:14px;
      width:100%;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.08);
      display: flex;
      border-radius: 4px;
      padding:6px 0px;
      align-items: center;
      justify-content: center;
    }

    .sound-box{

    }

    .sound-box .sound-box-item p {
      font-size:14px;
    }
  }

  @media all and (max-width: 400px) {
    .content-option-box div:first-child .mr-16 {
      margin-right: 8px;
    }
  }

  @media all and (max-width: 350px) {
    .content-option-box {
      display: block;
    }

    .content-option-box div:first-child {
      margin-bottom: 8px;
    }
  }
</style>
<style src="@/assets/css/mysound.css"></style>
