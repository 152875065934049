import { render, staticRenderFns } from "./BrandDetail.vue?vue&type=template&id=1a31ce0f&scoped=true"
import script from "./BrandDetail.vue?vue&type=script&lang=js"
export * from "./BrandDetail.vue?vue&type=script&lang=js"
import style0 from "./BrandDetail.vue?vue&type=style&index=0&id=1a31ce0f&prod&scoped=true&lang=css"
import style1 from "@/assets/css/mysound.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a31ce0f",
  null
  
)

export default component.exports