<template>
    <div class="time_modal modal open_time">
        <div class="time_modal_box modal_box">
            <div class="select_box">
                <div class="select-box-area">
                    <div class="label select-box" @mouseover.once="timeSet">{{ time }}시간마다</div>
                    <div class="select-box-dropDown">
                        <ul class="optionList">
                            <li class="optionItem" :class="!repeatList[0] ? 'on' : ''" @click="checkStatus(repeatList[0])"><p>1시간마다</p></li>
                            <li class="optionItem" :class="!repeatList[1] ? 'on' : ''" @click="checkStatus(repeatList[1])"><p>2시간마다</p></li>
                            <li class="optionItem" :class="!repeatList[2] ? 'on' : ''" @click="checkStatus(repeatList[2])"><p>3시간마다</p></li>
                            <li class="optionItem" :class="!repeatList[3] ? 'on' : ''" @click="checkStatus(repeatList[3])"><p>6시간마다</p></li>
                            <li class="optionItem" :class="!repeatList[4] ? 'on' : ''" @click="checkStatus(repeatList[4])"><p>12시간마다</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btn_area">
                <!-- <button id="prev_btn">취소</button> -->
                <button @click="selectTime">적용</button>
            </div>
        </div>

        <possibleConfirm v-if="possibleStatus" @closePossConfirm="possibleStatus = false"></possibleConfirm>
        <impossibleConfirm v-if="impossibleStatus" @closeImpossConfirm="closeImpossConfirm"></impossibleConfirm>
    </div>
</template>

<script>
import possibleConfirm from '@/components/modal/Player/mysound/confirm/possibleConfirm';
import impossibleConfirm from '@/components/modal/Player/mysound/confirm/impossibleConfirm';
import { defineComponent } from "@vue/composition-api";
// import $ from 'jquery';

export default defineComponent({
  name: 'repeatPc',

  components: {
    possibleConfirm,
    impossibleConfirm
  },

  props: [
    'repeatTime',
    'repeatList'
  ],

  data () {
    return {
      time: null,
      possibleStatus: false,
      impossibleStatus: false
    };
  },

  created () {
    this.time = this.repeatTime;
  },

  methods: {
    selectTime () {
      this.$emit('insertPc', this.time);
      this.$emit('closePc');
    },

    checkStatus (item) {
      if (item) {
        this.possibleStatus = true;
      } else {
        this.impossibleStatus = true;
      }
    },

    closeImpossConfirm (item) {
      this.time = item;
      this.impossibleStatus = false;
    },

    timeSet () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', e => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.time = item.textContent.substring(0, 1);
        if (item.textContent.substring(0, 2) === '12') {
          this.time = item.textContent.substring(0, 2);
        }

        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
});
</script>

<style scoped>
  .time_modal {
    display: block;
  }

  .select-box-dropDown ul {
    width: 100%;
  }

  .select-box-area {
    width: 100%;
  }

  .time_modal_box .select_box {
    background: #383838;
    width: 100%;
    margin-bottom: 6px;
  }

  .optionItem.on {
    /* pointer-events: none; */
    opacity: 0.3;
  }
</style>
