<template>
    <div class="mysound-area">
        <div class="mysound-popup">
            <div class="web">
                <div class="mysound-header" @click="[closeUploadBox(), moCloseTooltip()]">
                    <div class="mysound-header-title">
                        <p>마이 사운드</p>
                    </div>
                    <div class="mysound-close" @click="$emit('closePopup')">
                        <img src="/media/images/icon_close_40.svg" alt="">
                    </div>
                </div>
            </div>

            <div class="mobile">
                <div class="mo-mysound-header">
                    <div></div>
                    <div>
                        <p>마이 사운드</p>
                    </div>
                    <div class="mo-mysound-close" @click="$emit('closePopup')">
                        <img src="/media/images/icon_close_24.svg" alt="">
                    </div>
                </div>
            </div>

            <div class="mysound-category" @click="closeUploadBox">
                <p class="txt-white" @click="changeCategory(true)" :class="categoryStatus ? 'on' : ''" v-if="brandId != null">
                    브랜드 음원
                    <img class="web" @mouseover="openTooltip" @mouseleave="closeTooltip" src="media/img/mysound/question-line.svg" fill="rgba(255,255,255,1)" v-if="categoryStatus">
                    <img class="mobile" @click="moOpenTooltip" src="media/img/mysound/question-line.svg" fill="rgba(255,255,255,1)" v-if="categoryStatus">
                </p>
                <p class="txt-white" @click="[changeCategory(false), moCloseTooltip()]" :class="!categoryStatus ? 'on' : ''">퍼스널</p>

                <div class="mysound-tooltip" v-if="tooltipStatus">
                    <p>브랜드 음원이란?</p>
                    <p>본사에서 제공하는 음원으로 본사 담당자가 배포한 음원 리스트입니다.</p>
                </div>
            </div>

            <div class="mysound-main-content" v-if="categoryStatus" @click="[closeUploadBox(), moCloseTooltip()]">
                <!-- 브랜드 음원 리스트 -->
                <div v-if="categoryStatus && brandId != null">
                    <div class="brand-list" v-if="brandMusicList != null">
                        <div class="list-item" v-for="(item, i) in brandMusicList" :key="i">
                            <div @click="openBrandDetail(item)">
                                <p class="txt-white" v-html="item.brandMusicNm" />
                                <img src="/media/img/s_next.svg" alt="">
                            </div>
                            <div>
                                <div class="alarm_box">
                                    <span :class="item.playAt === 'Y' ? 'active1' : ''" @click="cnangeBrandPlayAt(item)"></span>
                                    <span class="move" :class="item.playAt === 'Y' ? 'active1_1' : ''" @click="cnangeBrandPlayAt(item)"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="brand-list brand-empty" v-else>
                        <img src="/media/img/empty_payment.png" width="40px">
                        <p>등록된 사운드가 없습니다</p>
                    </div>
                </div>
            </div>

            <div class="mysound-main-content" v-else @click="[closeUploadBox(), moCloseTooltip()]">
                <!-- 퍼스널 리스트 -->
                <div v-if="pTotal > 0">
                    <div class="personal-list">
                        <div class="list-item" v-for="(item, i) in personalList" :key="i">
                            <div @click="openPersonalDetail(item)">
                                <p class="txt-white" v-html="item.brandMusicNm" />
                                <img src="/media/img/s_next.svg" alt="">
                            </div>
                            <div>
                                <div class="alarm_box">
                                    <span :class="item.mySoundDetail.playAt === 'Y' ? 'active1' : ''" @click="changeMysoundPlayAt(item)"></span>
                                    <span class="move" :class="item.mySoundDetail.playAt === 'Y' ? 'active1_1' : ''" @click="changeMysoundPlayAt(item)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mysound-upload-content" v-else>
                    <div class="content-title mb-16">
                        <p>나만의 음원을 등록하고<br>
                            공간을 더 특별하게 만들어보세요.</p>
                        <p>(최대 3개 등록 가능)</p>
                    </div>

                    <div class="content-list">
                        <div class="content-item" @click="selectItem('파일')" :class="itemOption === '파일' ? 'option' : ''">
                            <div class="content-file-upload">
                                <img src="/media/img/mysound/icon_record_upload.svg" alt="">
                            </div>
                            <div>
                                <p class="content-item-title">파일 업로드</p>
                                <p>오디오 파일을 직접 업로드하실 수 있습니다.</p>
                            </div>
                        </div>

                        <div class="content-item" @click="selectItem('음성')" :class="itemOption === '음성' ? 'option' : ''">
                            <div>
                                <img src="/media/img/mysound/icon_record_voice.svg" alt="">
                            </div>
                            <div>
                                <p class="content-item-title">음성 녹음</p>
                                <p>직접 녹음한 음성을 오디오 파일로 등록하실 수 있습니다.</p>
                            </div>
                        </div>

                        <div class="content-item" @click="selectItem('텍스트')" :class="itemOption === '텍스트' ? 'option' : ''">
                            <div>
                                <img src="/media/img/mysound/icon_record_text.svg" alt="">
                            </div>
                            <div>
                                <p class="content-item-title">텍스트 입력</p>
                                <p>직접 입력한 텍스트를 음성으로 변환해서 등록하실 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="brandsound-footer" v-if="categoryStatus" @click="moCloseTooltip">
                <div class="content-note">
                    <div>
                        <p class="note-title">유의사항</p>
                        <div class="note-list">
                            <p>- 마이 사운드 내의 동시 재생 가능 곡 수는 최대 5곡입니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mysound-footer" v-else @click="moCloseTooltip">
                <div class="content-note" v-if="!categoryStatus && pTotal > 0" @click="closeUploadBox">
                    <div>
                        <p class="note-title">유의사항</p>
                        <div class="note-list">
                            <p>- 음원은 최대 3개까지 등록 가능합니다.</p>
                            <p>- 마이 사운드 내의 동시 재생 가능 곡 수는 최대 5곡입니다.</p>
                        </div>
                    </div>
                </div>

                <div class="common-btn-style" v-if="!categoryStatus && pTotal === 0" @click="openUploadPopup">
                    <p>다음</p>
                </div>

                <div class="upload-area" v-if="!categoryStatus && pTotal > 0">
                    <div class="upload-btn-style" @click="openUploadBox">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.16016" y="11.2383" width="22" height="1.83333" fill="white" stroke="white"/>
                            <rect x="13.0781" y="1.1543" width="22" height="1.83333" transform="rotate(90 13.0781 1.1543)" fill="white" stroke="white"/>
                        </svg>
                    </div>

                    <div class="upload-box" v-if="uploadBoxStatus">
                        <div class="upload-box-item style-cursor" @click="openUploadPopup2('파일')">
                            <div class="upload-box-image">
                                <img src="/media/img/mysound/icon_record_upload.svg" alt="">
                            </div>
                            <div>
                                <p class="txt-white">파일 업로드</p>
                            </div>
                        </div>

                        <div class="upload-box-item style-cursor" @click="openUploadPopup2('음성')">
                            <div class="upload-box-image">
                                <img src="/media/img/mysound/icon_record_voice.svg" alt="">
                            </div>
                            <div>
                                <p class="txt-white">음성 녹음</p>
                            </div>
                        </div>

                        <div class="upload-box-item style-cursor" @click="openUploadPopup2('텍스트')">
                            <div class="upload-box-image">
                                <img src="/media/img/mysound/icon_record_text.svg" alt="">
                            </div>
                            <div>
                                <p class="txt-white">텍스트 입력</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BrandDetail v-if="brandDetailStatus" :musicInfo="musicInfo" @closeAppendPopup="closeAppendPopup" @getList="getList" @closePopup="closePopup"></BrandDetail>
        <PersonalDetail v-if="personalDetailStatus" :musicInfo="musicInfo" @refresh="refresh" @closeAppendPopup="closeAppendPopup" @closePopup="closePopup"></PersonalDetail>
        <MySoundFile v-if="fileStatus" @refresh="refresh" @closeAppendPopup="closeAppendPopup" @closePopup="closePopup"></MySoundFile>
        <MySoundVoice v-if="voiceStatus" @refresh="refresh" @closeAppendPopup="closeAppendPopup"  @closePopup="closePopup"></MySoundVoice>
        <MySoundText v-if="textStatus" @refresh="refresh" @closeAppendPopup="closeAppendPopup"  @closePopup="closePopup"></MySoundText>
        <UnableToRegister v-if="confirmStatus" @closeConfirm="closeConfirm"></UnableToRegister>
        <MaxConfirm v-if="maxConfirmStatus" @closeConfirm="closeConfirm"></MaxConfirm>
        <Loading v-if="loading"></Loading>
    </div>
</template>

<script>
import BrandDetail from '@/components/modal/Player/mysound/BrandDetail.vue';
import PersonalDetail from '@/components/modal/Player/mysound/PersonalDetail.vue';
import MySoundFile from '@/components/modal/Player/mysound/MySoundFile.vue';
import MySoundVoice from '@/components/modal/Player/mysound/MySoundVoice.vue';
import MySoundText from '@/components/modal/Player/mysound/MySoundText.vue';
import UnableToRegister from '@/components/modal/Player/mysound/confirm/UnableToRegister.vue';
import MaxConfirm from '@/components/modal/Player/mysound/confirm/MaxConfirm.vue';
import Loading from '@/components/modal/Player/mysound/etc/Loading.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  props: [
    'brandMusic',
    'brandId'
  ],

  components: {
    BrandDetail,
    PersonalDetail,
    MySoundFile,
    MySoundVoice,
    MySoundText,
    UnableToRegister,
    MaxConfirm,
    Loading
  },

  data () {
    return {
      // 카테고리
      categoryStatus: true,
      tooltipStatus: false,

      // 전체 실행 개수
      brandTotalPlay: 0,
      personalTotalPlay: 0,

      // 브랜드
      brandMusicList: null,
      brandDetailStatus: false,

      // 퍼스널
      personalDetailStatus: false,
      personalList: null,
      pTotal: 0,

      // 업로드
      itemOption: '',
      uploadBoxStatus: false,

      // 파일
      fileStatus: false,

      // 음성
      voiceStatus: false,

      // 텍스트
      textStatus: false,

      // 컨펌
      confirmStatus: false,
      maxConfirmStatus: false,

      // 로딩
      loading: false
    };
  },

  created () {
    // 브랜드 아이디가 없을 경우 카테고리 퍼스널로 변경
    if (this.brandId == null) {
      this.changeCategory(false);
    }

    if (this.brandMusic != null) {
      const list = [];
      for (const i in this.brandMusic) {
        if (this.brandMusic[i].listType !== 'personal') {
          list.push(this.brandMusic[i]);
        }
      }
      this.brandMusicList = list;

      // 브랜드 음원 실행 개수
      for (const i in this.brandMusicList) {
        if (this.brandMusicList[i].playAt === 'Y') {
          this.brandTotalPlay++;
        }
      }
    }

    // 퍼스널 리스트 불러오기
    this.getPersonalList('first');
  },

  methods: {
    // 카테고리 변경
    changeCategory (item) {
      this.categoryStatus = item;
    },

    openTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    closeTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moOpenTooltip () {
      this.tooltipStatus = !this.tooltipStatus;
    },

    moCloseTooltip () {
      this.tooltipStatus = false;
    },

    // 브랜드 음원 실행 여부 변경
    cnangeBrandPlayAt (item) {
      if ((this.brandTotalPlay + this.personalTotalPlay) >= 5 && item.playAt === 'N') {
        // 재생 가능 곡 수는 최대 5개
        this.maxConfirmStatus = true;
        return false;
      }

      this.loading = true;

      const brandMusicId = item.brandMusicId;
      const spaceId = VueCookies.get("spaceId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/player/updateBrandSpaceMusicPlayAt', { brandMusicId, spaceId }, { headers })
        .then(() => {
          item.playAt = item.playAt === 'Y' ? 'N' : 'Y';
          if (item.playAt === 'Y') {
            this.brandTotalPlay++;
          } else {
            this.brandTotalPlay--;
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 브랜드 상세 페이지 이동
    openBrandDetail (item) {
      this.musicInfo = item;
      this.brandDetailStatus = true;
    },

    // 퍼스널 리스트 불러오기
    getPersonalList (item) { // 퍼스널 리스트 불러오기
      const spaceId = VueCookies.get('spaceId');
      const userId = VueCookies.get('userId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      const limit = 20;
      // const page = this.page;
      // const offset = (page - 1) * this.limit;
      const offset = 0;

      axios
        .post('/api/mysound/getListOfMySound', { spaceId, limit, offset, userId }, { headers })
        .then(res => {
          this.personalList = res.data.result.listResult;
          this.pTotal = res.data.result.total;
          this.personalTotalPlay = 0;

          for (var i in this.personalList) {
            if (this.personalList[i].mySoundDetail.playAt === 'Y') {
              this.personalTotalPlay++;
            }
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          if (item === 'first') {
            this.checkPersonalList();
          }

          if (item === 'refresh') {
            this.closeAppendPopup();
          }
        });
    },

    openPersonalDetail (item) {
      this.musicInfo = item;
      this.personalDetailStatus = true;
    },

    // 퍼스널 음원 실행 여부 변경
    async changeMysoundPlayAt (item) {
      if ((this.brandTotalPlay + this.personalTotalPlay) >= 5 && item.mySoundDetail.playAt === 'N') {
        // 재생 가능 곡 수는 최대 5개
        this.maxConfirmStatus = true;
        return false;
      }

      this.loading = true;

      let cnt = 0;
      if (item.mySoundDetail.playAt === 'N' && item.mySoundDetail.playType === null) {
        cnt++;
      }

      const userId = VueCookies.get('userId');
      const brandMusicNm = item.brandMusicNm;
      const brandMusicId = item.brandMusicId;
      const playAt = item.mySoundDetail.playAt = item.mySoundDetail.playAt === 'Y' ? 'N' : 'Y';

      const mySoundDetail = {
        playAt: playAt,
        selectTime: item.mySoundDetail.selectTime,
        startTime: item.mySoundDetail.startTime,
        repeatTime: item.mySoundDetail.repeatTime,
        playType: item.mySoundDetail.playType,
        brandSpaceMusicId: item.mySoundDetail.brandSpaceMusicId
      };

      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/mysound/updateMySound', { userId, brandMusicNm, brandMusicId, mySoundDetail }, { headers })
        .then(() => {
          if (cnt > 0) {
            this.openPersonalDetail(item);
          } else {
            this.getPersonalList('');
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async checkPersonalList () {
      for (const i in this.personalList) {
        if (this.personalList[i].mySoundDetail.playAt === 'Y' && this.personalList[i].mySoundDetail.playType == null) {
          await this.changeMysoundPlayAt(this.personalList[i]);
        }
      }
    },

    selectItem (item) {
      this.itemOption = item;
    },

    openUploadPopup () {
      if (this.personalList.length < 3) {
        if (this.itemOption === '파일') {
          this.fileStatus = true;
        } else if (this.itemOption === '음성') {
          this.voiceStatus = true;
        } else {
          this.textStatus = true;
        }
      }
    },

    openUploadPopup2 (item) {
      this.closeUploadBox();
      if (!this.personalList.length < 3) {
        if (item === '파일') {
          this.fileStatus = true;
        } else if (item === '음성') {
          this.voiceStatus = true;
        } else {
          this.textStatus = true;
        }
      }
    },

    openUploadBox () {
      if (this.personalList.length < 3) {
        this.uploadBoxStatus = true;
      } else {
        this.confirmStatus = true;
      }
    },

    closeUploadBox () {
      this.uploadBoxStatus = false;
    },

    closeAppendPopup () {
      this.checkPersonalList();
      this.brandDetailStatus = false;
      this.personalDetailStatus = false;
      this.fileStatus = false;
      this.voiceStatus = false;
      this.textStatus = false;
    },

    closeConfirm () {
      this.confirmStatus = false;
      this.maxConfirmStatus = false;
    },

    refresh () {
      this.getPersonalList('refresh');
    },

    getList () { // 브랜드 음원 정보 수정 후 닫기
      this.$emit('getList');
      this.closeAppendPopup();
    },

    closePopup () { // 마이사운드 팝업 닫기
      this.$emit('closePopup');
    }
  }

};
</script>

<style scoped>
  .mysound-popup .mo_space_header{
      display: none;
  }

  .content-title p:first-child {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #fff;
  }

  .content-title p:last-child {
    font-weight: 300;
    margin-top:16px;
    font-size: 16px;
    letter-spacing: -1px;
    color: #fff;
    opacity: .64;
  }

  .content-item {
    display: flex;
    align-items: center;
    padding: 0 18px;
    border-bottom: 1px solid hsla(0,0%,100%,.04);
    cursor: pointer;
    height: 110px;
  }

  .content-item:hover {
    background: rgba(255, 255, 255, 0.04);
    transition: all 0.3s;
  }

  .content-item:last-child {
    border-bottom:0;
  }

  .content-item.option {
    border: 1px solid #FF2D55;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #151515;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }

  .content-item div:first-child {
    background: rgba(255, 255, 255, 0.08);
    width:52px;
    height:52px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right:12px;
  }

  .content-item div:first-child img {
    width:22px;
  }

  .content-item div:last-child {
    width: 80%;
  }

  .content-item div:last-child p {
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: rgba(225,225,225, 0.64);
  }

  .content-item div:last-child .content-item-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    color: #fff;
  }

  .upload-area {
    position: absolute;
    width: 60px;
    height: 60px;
    bottom: 42px;
  }

  .upload-box {
    width: 152px;
    position: absolute;
    bottom: 120%;
    right: 5%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    backdrop-filter: drop-shadow(10px 16px 20px #000);
  }

  .upload-box-item {
    display: flex;
    align-items: center;
    padding: 10px 25px 10px 12px;
  }

  .upload-box-item:hover {
    background: rgba(255, 255, 255, 0.04);
    transition: all 0.3s;
  }

  .upload-box-item:last-child {
    margin-bottom: 0;
  }

  .upload-box-item div:first-child {
    width: 32px;
    height: 32px;
    background: #282828;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
  }

  .upload-box-item div:first-child img {
    width: 14px;
  }

  .upload-box-item div:last-child p {
    font-size: 16px;
    color: #fff;
    margin-left: 8px;
  }

  @media all and (max-width: 500px) {
    .mysound-popup .mysound-header{
      margin-bottom: 0;
    }
    .mysound-popup .mo_space_header{
      display: block;
      background: #282828;
    }

    .mysound-popup .mysound-header .mysound-header-title{
      display: none;
    }

    .content-title p:first-child {
      font-size: 20px;
      line-height: 28px;
    }

    .content-title p:last-child {
      font-size: 14px;
      margin-top: 6px;
    }

    .content-item{
      padding:0 14px;
    }
    .content-item div:last-child .content-item-title {
      font-size: 16px;
    }
    .mysound-tooltip {
      width: 90%;
    }
    .upload-box{
      width: 162px;
    }
  }
</style>
<style src="@/assets/css/mysound.css"></style>
